<template>
  <div>
    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-card :title="$t('forms.view')">
          <actions
            slot="actions"
            crud-links="forms"
            controller="DynamicForms"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          />
          <va-inner-loading :loading="loading">
            <text-list v-if="Number.isInteger(form.type)" :title="$t('tables.headings.type')">
              {{ typeLabel(form.type) }}
            </text-list>
            <text-list v-if="form.valid_since" :title="$t('tables.headings.active_since')">
              {{ dateLabel(form.valid_since) }}
            </text-list>
            <text-list v-if="form.valid_until" :title="$t('tables.headings.active_until')">
              {{ dateLabel(form.valid_until) }}
            </text-list>
          </va-inner-loading>
        </va-card>
      </div>
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-collapse withBackground>
          <span slot="header">{{ $t('layout.preview') }}</span>
          <template slot="body">
            <div class="row">
              <form-builder :loading="loading" :questions="questions" />
            </div>
          </template>
        </va-collapse>
      </div>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const FormBuilder = () => import(/* webpackPrefetch: true */ '@/components/extras/FormBuilder')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'forms-view',
  components: {
    Actions,
    TextList,
    FormBuilder,
  },
  data () {
    return {
      form: {},
      error: false,
      questions: [],
      loading: false,
      actions: ['index', 'new', 'edit', 'delete'],
    }
  },
  watch: {
    async form (val) {
      const definition = JSON.parse(val.definition)
      if (definition.length) {
        this.questions = definition.slice(0)
      }
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    typeLabel (type) {
      let translation = ''
      switch (type) {
        case 0: translation = 'forms.inputs.types.team'; break
        case 1: translation = 'forms.inputs.types.district'; break
      }

      return this.$t(translation)
    },
    dateLabel (date) {
      return this.$date.format(date, 'MMMM - yyyy')
    },
    routeBuilder (id) {
      return `forms/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const formId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(formId))
      } catch (err) {
        // console.log('Error fetching mti data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.form = u.data.data
    },
  },
}
</script>
